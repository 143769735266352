import type { UseFetchReturn } from "@/types";

export class BaseExtensionAdapter {
  protected config;
  protected contextToken: string;

  constructor() {
    this.config = useRuntimeConfig();

    const { sessionContext } = useSessionContext();
    if (!sessionContext.value) {
      console.error("Session context is not available");
    }
    this.contextToken = sessionContext.value?.token || "";
  }

  protected makeFetch = async <T>(url: string, options: RequestInit = {}) => {
    const baseHeaders = {
      "Content-Type": "application/json",
      "sw-access-key": this.config.public.shopware.shopwareAccessToken,
      "sw-context-token": this.contextToken,
    };

    return await useFetch<T>(url, {
      ...options,
      headers: {
        ...baseHeaders,
        ...options.headers,
      },
    }) as UseFetchReturn<T>;
  };
}
