import { BaseExtensionAdapter } from "../../Base.adapter";

export class CrehlerBackInStockExtensionAdapter extends BaseExtensionAdapter {
  constructor() {
    super();
  }

  extensionBaseUrl = `${this.config.public.shopware.shopwareEndpoint}/back-in-stock`;
  extensionUrls = {
    subscribe: `${this.extensionBaseUrl}/subscribe`,
  } as const;

  subscribe = (options: {
    productId: string;
    customerId?: string;
    email: string;
  }) => {
    return this.makeFetch(this.extensionUrls["subscribe"], {
      method: "POST",
      body: options,
    });
  };
}
